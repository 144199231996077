import { Button, DateRangePicker, Link, Pagination, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { useState, useMemo, useEffect, Key, useCallback } from "react";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { getNormalizedDateTime } from "@/utils";
import { FaLockOpen } from "react-icons/fa6";
import { LockLog } from "@/api/locks/locks.ts";
import { useAppSelector } from "@/hooks/useAppSelector";

function isLog(log: LockLog): log is LockLog {
  return typeof log === 'object' && 'id' in log
};

function renderColumn(item: any, key: Key) {
  switch(key) {
    case 'CREATED_AT':
      return getNormalizedDateTime(item[key]);
  }
}

export function LockLogsPage() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [data, setData] = useState<LockLog[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number | null>(null);
  const handleError = useErrorHandling();

  const [activeDateFrom, setActiveDateFrom] = useState<string | null>(null);
  const [activeDateTo, setActiveDateTo] = useState<string | null>(null);
  const [activeDate, setActiveDate] = useState<any>(null);

  const offices = useAppSelector(state => state.handbooks.office);
  const office = useMemo(() => offices.find(office => office.id === Number(id)), [offices, id]);

  const pages = useMemo(() => {
    return total ? Math.ceil(total / rowsPerPage) : 0;
  }, [total, rowsPerPage]);

  const loadingState = isLoading ? "loading" : "idle";

  const resetFilters = useCallback(() => {
    setActiveDate(null);
    setActiveDateFrom(null);
    setActiveDateTo(null);
  }, []);

  useEffect(() => {
    setLoading(true);
    api.locks.logs(Number(id), ((page - 1) * 10), 10, activeDateFrom!, activeDateTo!)
      .then(res => {
        setData(res.data);
        setTotal(res.total_count);

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [page, activeDateFrom, activeDateTo]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link onClick={_e => { api.locks.unlock(office?.id || 0).then(_res => toast.success("Замок успешно открыт!")) }} className="flex flex-row gap-2 items-center p-4 rounded-xl font-medium text-foreground-600 cursor-pointer">
            <FaLockOpen />
            Открыть замок
          </Link>
          <Link href={`/dashboard/lock/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Журнал действий
          </Link>
          <Link href={`/dashboard/lock/${id}/passcodes`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Пароли
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <span className="font-medium">
                  Журнал действий
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-2 items-center max-w-fit">
              <DateRangePicker
                label="Выберите отрезок времени"
                variant="bordered"
                onChange={e => {
                  setActiveDate(e);
                  setActiveDateFrom(`${e.start.year}-${e.start.month}-${e.start.day}`);
                  setActiveDateTo(`${e.end.year}-${e.end.month}-${e.end.day}`);
                }}
                value={activeDate}
              />
            </div>
            <div className="flex flex-row gap-2 max-w-fit">
              <Button size="sm" variant="flat" color="primary" onClick={resetFilters}>Сбросить</Button>
            </div>
            <Table
              removeWrapper
              bottomContent={
                pages > 0 ? (
                  <div className="flex w-full justify-center">
                    <Pagination
                      isCompact
                      showControls
                      showShadow
                      color="primary"
                      page={page}
                      total={pages}
                      onChange={(page) => setPage(page)}
                    />
                  </div>
                ) : null
              }
            >
              <TableHeader>
                <TableColumn key="CREATED_AT">Дата</TableColumn>
                <TableColumn key="user_id">Пользователь</TableColumn>
              </TableHeader>
              <TableBody
                items={data ?? []}
                loadingContent={<Spinner />}
                loadingState={loadingState}
                emptyContent={<span>Записей не обнаружено</span>}
              >
                {(item) => (
                  <TableRow key={isLog(item) ? item.id : item}>
                    {(columnKey) => <TableCell>{renderColumn(item, columnKey)}</TableCell>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
