import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LayoutNavbar from "../components/navbar/navbar";
import useDarkMode from "use-dark-mode";
import { ToastContainer, toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { api } from "@/api";
import { useAppDispatch } from "@/hooks/useAppDispatch.ts";
import { setPermissionRole, setPermissionScopes, setUser } from "../store/user/reducer";
import { User } from "@/api/auth/auth.ts";
import { Button, NextUIProvider } from "@nextui-org/react";
import { Navigation } from "../components/navigation/navigation";
import { setTicketCategories, setLocations, setOffices, setRenters, setUsers } from "../store/handbooks/reducer";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { addNotification } from "../store/notifications/reducer";
import { NotificationIcon } from "../store/notifications/types";
import ym from "react-yandex-metrika";

export function Layout({ children }: { children?: React.ReactNode; }) {
  const darkMode = useDarkMode(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, setDUser] = useState<User | null>(null);
  const renter = useAppSelector(state => state.location.location);
  const slocation = useLocation();

  useEffect(() => {
    document.querySelector("body")?.classList.remove("dark", "text-foreground", "bg-background");
    document.querySelector("body")?.classList.add(darkMode.value ? "dark" : "white", "text-foreground", "bg-background");
  }, [darkMode.value]);

  useEffect(() => {
    api.notifications.list()
      .then(res => {
        res.data.forEach(notification => {
          dispatch(addNotification({
            id: notification.id,
            icon: NotificationIcon.INFO,
            title: notification.title,
            message: notification.message,
            datetime: notification.created_at,
            is_read: notification.is_read
          }));
        })
      })
      .catch(err => {
        console.log(err);
      });

    api.auth.me().then(data => {
      setDUser(data.data);
      dispatch(setUser(data.data));
    });

    if (location.href.indexOf("selectLocation") == -1 && !localStorage.getItem("locationId")) {
      navigate("/dashboard/selectLocation");
    }

    api.auth.getMyPermissions().then(data => {
      if (renter.id !== 0) {
        const renterPermissions = data.data.find(el => el.renter.id == renter.id);
        if (!renterPermissions) return;

        const permissions: string[] = [];
        permissions.push(...Object.keys(renterPermissions.permissions));

        dispatch(setPermissionRole(renterPermissions.role));
        dispatch(setPermissionScopes(permissions));
      }
    });

    const url = slocation.pathname + slocation.search;
    ym("hit", url);

    Promise.allSettled([
      api.tickets.categories.list(),
      api.locations.list(),
      api.officies.list(),
      api.renters.list(),
      api.renters.listEmployees()
    ])
      .then(results => {
        const errors: Record<string, any> = {};

        results.forEach((result, index) => {
          switch (index) {
            case 0:
              if (result.status === 'fulfilled') {
                // @ts-ignore
                dispatch(setTicketCategories(result.value.data));
              } else {
                errors.ticketCategories = result.reason;
              }
              break;
            case 1:
              if (result.status === 'fulfilled') {
                // @ts-ignore
                dispatch(setLocations(result.value.data));
              } else {
                errors.locations = result.reason;
              }
              break;
            case 2:
              if (result.status === 'fulfilled') {
                // @ts-ignore
                dispatch(setOffices(result.value.data));
              } else {
                errors.offices = result.reason;
              }
              break;
            case 3:
              if (result.status === 'fulfilled') {
                // @ts-ignore
                dispatch(setRenters(result.value.data));
              } else {
                errors.renters = result.reason;
              }
              break;
            case 4:
              if (result.status === 'fulfilled') {
                // @ts-ignore
                dispatch(setUsers(result.value.data));
              } else {
                errors.users = result.reason;
              }
              break;
            default:
              break;
          }
        });

        if (Object.keys(errors).length > 0) {
          console.error('Some requests failed:', errors);
        }
      });
  }, [slocation.pathname]);

  const linkTelegram = useCallback(() => {
    api.auth.generateTelegramLink()
      .then(data => {
        location.href = data.data.link;
      })
      .catch(err => {
        console.error(err);
        toast.error("У нас произошли некоторые проблемы с привязкой вашего аккаунта к Telegram. Попробуйте позже или обратитесь в поддержку");
      })
  }, [user]);

  return (
    <>
      <NextUIProvider navigate={navigate}>
        <main id="main" className={`${darkMode.value ? 'dark bg-black' : 'bg-zinc-100'} text-foreground`}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="layout">
            <LayoutNavbar />
            <div className="layout__content lg:max-h-screen">
              {(typeof user?.telegram == null || user?.telegram == 0 || user?.telegram == null) && (
                <div className="w-full bg-primary-100 flex flex-col gap-2 rounded-xl p-4 mb-4">
                  <span className="text-sm">Заметили, что у вас всё ещё не привязан Telegram. А зря - у нас есть удобный Telegram-бот, облегчающий работу с кабинетом.</span>
                  <Button variant="flat" color="primary" size="sm" onClick={linkTelegram} className="mt-2 max-w-fit">Класс, хочу попробовать</Button>
                </div>
              )}
              <Navigation />
              {children || <Outlet />}
            </div>
          </div>
        </main>
      </NextUIProvider>
    </>
  );
}