import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { Renter } from "@/api/renters/renters.ts";

interface IEditLockForm {
  display_name: string;
  orgn: string;
  phone_number: string;
  email: string;
  address: string;
}

const editLockFields: InputField[] = [
  {
    label: "Название компании",
    name: "display_name",
    placeholder: "ОАО 'МММ)'",
    type: "text",
    isRequired: true
  }, {
    label: "ОРГН",
    name: "orgn",
    placeholder: "123...",
    type: "text",
    isRequired: true
  }, {
    label: "Номер телефона",
    name: "phone_number",
    placeholder: "+7955...",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес почты",
    name: "email",
    placeholder: "example@mail.ru",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "Ваш физический адрес",
    type: "text",
    isRequired: true
  }
]

export function EditRenterModal({ disclosure, renter }: { disclosure: ReturnType<typeof useDisclosure>, renter: Renter | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: IEditLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.renters.update(renter!.id, {
        display_name: data.display_name,
        email: data.email,
        orgn: data.orgn,
        phone_number: data.phone_number,
        address: data.address
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  return (
    <ModalBuilder
      title="Редактирование арендатора"
      fields={editLockFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: renter?.display_name,
        email: renter?.email,
        orgn: renter?.orgn,
        phone_number: renter?.phone_number,
        address: renter?.address
      }} />
  );
}