import rootStore from "@/app/store";
import { User } from "@/api/auth/auth";
import crud, { ApiResponse } from "@/api/crud"

export interface Renter {
  id: number;
  display_name: string;
  orgn: string;
  phone_number: string;
  email: string;
  holder: number;
  address: string;
}

export interface CreateRenterParams {
  display_name: string;
  orgn: string;
  phone_number: string;
  email: string;
  address: string;
}

export interface UpdateRenterParams {
  display_name: string;
  orgn: string;
  phone_number: string;
  email: string;
  address: string;
}

export function RentersAPI(baseUrl: string) {
  return {
    list: async () => {
      return await crud.get(`${baseUrl}/renters`, undefined, true) as ApiResponse<Renter[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/renters/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<Renter>;
    },
    create: async (params: CreateRenterParams) => {
      return await crud.post(`${baseUrl}/renters`, params, true) as ApiResponse<Renter>;
    },
    listEmployees: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/renters/${renterId}/employees/?renter_id=${renterId}`, undefined, true) as ApiResponse<User[]>;
    },
    update: async (id: number, params: UpdateRenterParams) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.put(`${baseUrl}/renters/${id}?renter_id=${renterId}`, params, true) as ApiResponse<Renter>;
    },
    transferOwnership: async (id: number, newUsername: string) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/renters/${id}/transfer/?renter_id=${renterId}&new_username=${newUsername}`, {}, true) as ApiResponse<boolean>;
    }
  }
}